<template>
  <div id="panel-header">
    <div class="header">{{ title }}</div>
  </div>
</template>
<script>
export default {
  name: "PanelHeader",
  props: ["title"]
};
</script>
<style scoped>
#panel-header {
  width: 100%;
  min-height: 55px;
  max-height: 55px;
  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  position: sticky;
  top: 50px;
  z-index: 1000;
}

.header {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  text-align: right;
  color: #121212;
  margin-right: 20px;
  font-family: "Vazir Medium FD";
}

@media (min-width: 992px) {
}
</style>
