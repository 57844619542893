<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div
      class="selected"
      :class="{ open: open, error: errorMassage, selectedWhite: selectedWhite }"
      @click="open = !open"
    >
      <img class="image-selected" :src="selectedImage" alt="" />
      <div class="card-selected-name">{{ selected }}</div>
      <div class="card-selected-card-number">{{ selectedCardNumber }}</div>
      <img
        class="dropdown-icon"
        src="../../assets/images/dropDownIcon.svg"
        alt=""
      />
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        class="item"
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option.bank_name;
          selectedImage = option.bank_image;
          selectedCardNumber = option.card_number;
          open = false;
          $emit('input', option);
        "
      >
        <img class="option-image" :src="option.bank_image" alt="" />
        <div class="card-option-name">{{ option.bank_name }}</div>
        <div class="card-option-card-number">{{ option.card_number }}</div>
      </div>
    </div>
    <div class="error-massage">
      {{ errorMassage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BankCardSelect",
  props: {
    options: {
      type: Array,
      required: true
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    errorMassage: {
      type: String,
      required: false
    },
    selectedWhite: {}
  },
  data() {
    return {
      // selected: this.options.length > 0 ? this.options[0].name : null,
      open: false,
      selected: null,
      selectedImage: null,
      selectedCardNumber: null,
      error: "",
      validated: false
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  }
};
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 100%;
  text-align: right;
  outline: none;
  height: 47px;
  line-height: 47px;
  direction: rtl;
}

.selected {
  background-color: rgba(31, 60, 136, 0.2);
  border-radius: 6px;
  color: #121212;
  padding: 3px 15px;
  cursor: pointer;
  user-select: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 31px;
}

.dropdown-icon {
  position: absolute;
  margin-right: 50px;
  left: 15px;
}

.option-image {
  width: 30px;
}

.card-option-name {
  margin-right: 10px;
}

.items {
  margin-top: 10px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  background: #ffffff;
  /* Drop shadow */

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  /*border-radius: 10px;*/
}

.item {
  color: #121212;
  padding-right: 20px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
}

.item:hover {
  background-color: rgba(31, 60, 136, 0.2);
}

.selectHide {
  display: none;
}

.card-option-card-number {
  margin-right: 20px;
}

.image-selected {
  width: 20px;
}

.card-selected-name {
  font-size: 14px;
  margin-right: 10px;
}

.card-selected-card-number {
  margin-right: 20px;
}

.error {
  border: 1px solid #ff6b6b;
}
.selectedWhite {
  background-color: #ffffff;
}

.error-massage {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ff6b6b;
  margin-top: -10px;
}
</style>
